<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg9 xl9">
      <va-card  class="flex mb-4">
        <va-card-content>
          <va-card-actions>
            <h4 class="display-4">Em breve</h4>
          </va-card-actions>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>

export default {
  name: "EmBreve"
}
</script>

<style scoped>

</style>
<template>
  <div>Redirecionando...</div>
</template>

<script>
export default {
  name: "Redirect",
  props: {
    goBackAfterRedirect: {type: Boolean, required: false, default: true},
    targetBlank: {type: Boolean, required: false, default: true},
    timeout: {type: Number, required: false, default: 10},
    url: {type: String, required: true },
  },
  mounted() {
    setTimeout(() => {
      let url = this.url
      if(this.url === 'url_servicos') {
        url = this.$Auth.getProp('url_servicos') || '/';
      }
      window.open(url, this.targetBlank ? '_blank' : '');
      if (this.goBackAfterRedirect) {
        window.history.back();
      }
    }, this.timeout);
  }
}
</script>

<style scoped>

</style>
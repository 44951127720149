import JSZip from 'jszip';

export async function downloadBase64File(base64:string, fileName:string): Promise<void>
{
    downloadBlobFile(await base64ToBlob(base64), fileName)
}

export function downloadBlobFile(blob: Blob, fileName:string): void
{
    const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }))
    downloadUrlFile(url, fileName)
    setTimeout(function(){
        window.URL.revokeObjectURL(url);
    }, 3000);
}

export function downloadUrlFile(url:string, fileName:string): void
{
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    setTimeout(function(){
        document.body.removeChild(downloadLink);
    }, 100);
}

export function base64ArrayFilesToZipAndDownload(files: { name:string, base64:string }[], name: string){
    const zip = new JSZip()
    files.forEach((file)=> zip.file(file.name, file.base64))
    zip.generateAsync({type: "blob" }).then((blob) => downloadBlobFile(blob, name))
}

export async function base64ToBlob(base64: string){
    const res = await fetch(base64)
    return res.blob();
}

export function bytesToMegaBytes(bytes: number): number {
    return bytes / (1024*1024);
}

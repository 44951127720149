<template>
  <monta-form :form="formLogin" @complete="submitFormLogin"/>
</template>

<script>
import MontaForm from "@/components/form/MontaForm";
import FormLoginStruct from "./FormLoginStruct";
import {FetchWs} from "@/functions/FetchWs";
import {Auth} from "@/controllers/Auth";

export default {
  name: 'login',
  components: {MontaForm},
  data() {
    return {
      formLogin: {
        struct: FormLoginStruct
      }
    }
  },
  methods: {
    async submitFormLogin(data){
      const base64 = btoa(data.login_email + ':' + data.login_pass);
      const response = await FetchWs('auth/login', 'GET',{},{
        Authorization: "Basic " + base64
      });
      if(response?.success && response?.data?.jwt) {
        const $Auth = new Auth();
        await $Auth.setLogin(response.data.jwt);
        this.$router.push('/')
      }
    }
  },
}
</script>
